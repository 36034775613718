import React from 'react';
/* style={{ backgroundImage: `url(${back})` }} */
import './header.css';

const Header = () => (
  <div className="background section__padding section__margin section__margin__2" >
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="none">Relax and Ride In Style <br></br>With Regal</h1>
      <p>Fast, convienant, polite ready to go where life takes you next. Regal stands by your side, reserve your ride with us today. </p>

      
      </div>
    </div>
  </div>
);

export default Header;
