import React from 'react';
import logo from '../../assets/logo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="none">Ride in luxury with Regal. Find your next adventure with Regal. </h1>
    </div>

    <div className="gpt3__footer-btn">
      <a href="#contact"><p>Request A Ride- Click Here</p></a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="logo" />
        <p>2000 Mallory Lane Suite 130-537<br></br>
          Franklin, TN 37067<br /></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <a href="#home"><p>Home</p></a>
        <a href="#about"><p>About</p></a>
        <a href="#reserve"><p>Reserve</p></a>
        <a href="#contact"><p>Contact</p></a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>(615) 314-5400</p>
        <p>info@regalrides.co</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 VORTEXX ENTERPRISES, LLC. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
