import React from 'react';
import Form from '../../components/formcontainer/Form';
import './contact.css';

 const Contact = () => (
  <div className="gpt3__contact section__padding section__margin" id="contact">
    <div className="gpt3__contact-heading">
      <h1 className="none">There are so many reasons to choose Regal. Come join us and see why!</h1>
      <p>Book a ride today!</p>
    </div>
    <div className="gpt3__contact-container">
      <Form />
    </div>
  </div>
);

export default Contact;
