import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo2 from '../../assets/logo2.png';
import logom from '../../assets/logo-mob.png'
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
/* style={{ backgroundImage: `url(${logo})` }} */
  return (
    
    <div className="navbar ">
      <div className="navbar-links">
        <div className="navbar-links_logo">
        </div>
        <div className="navbar-links_container ">
          <p><a href="#home">Home</a></p>
          <p><a href="#about">About</a></p>
          
          
            <img className="logo" src={logo2}/>
          
          
          <p><a href="#contact">Reserve</a></p>
          <p><a href="#contact">Contact</a></p>
        </div>
        
        <div className="navbar-mobile logo" >
          <img className="logo-m" src={logom}/>
        </div>
        
      </div>

     
      
    
      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          
        <div className="navbar-menu_container scale-up-center">
          
          <div className="navbar-menu_container-links">
          
          
        
            
            <p><a href="#home">Home</a></p>
            <p><a href="#wgpt3">About</a></p>
            <p><a href="#contact">Reserve</a></p>
            <p><a href="#contact">Contact</a></p>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
