import React from 'react';

import { Footer, Blog, Info, Features, Whyregal, Header, About } from './containers';
import { CTA, Navbar, Form} from './components';

import './App.css';
import Contact from "./containers/contact/Contact";

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    
    {/* <Brand /> */}
    
    <Whyregal />
    <Contact />
    
    <Features/>
    <CTA />
    <Blog />
    <About />
    <Footer />
  </div>
);

export default App;
