import React from 'react';
import Feature from '../../components/feature/Feature';

import './about.css';

const featuresData = [
  {
    /* title: 'Reserving Your Ride Is Fast', */
    text: 'Booking is quick, Call: (615) 314-5400 or fill out our form above.' ,
    
  },
  
];

const About = () => (
  <div className="about__features section__padding section__margin" id="about">
    <div className="about__features-heading">
      <h1 className="none">About Regal</h1>
      <p>Thanks to you!</p>
    </div>
    <div className="about__features-heading-2">
      <p>Regal has been proud to serve Middle Tennesse for years helping customers get from destination to destination quickly each and
         every time. As our fleet continues to grow we thank our valued customers for supporting our business and helping us grow into what Regal is today. We thank you.  </p>
    </div>
  </div>
);

export default About;