import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content ">
      <p>Request a ride</p>
      <h3>Fast transport just one click a way. Click here. </h3>
    </div>
    <div className="gpt3__cta-btn">
    <a href="#contact"> <button type="button">Get Started</button></a>
    </div>
  </div>
);

export default CTA;
