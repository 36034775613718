import React from 'react';
import Feature from '../../components/feature/Feature';
import './whyregal.css';

const Whyregal = () => (
  <div className="why_regal section__margin" id="wgpt3">
    <div className="why_regal-feature">
      <Feature title="Why Regal?" text="Regal has been serving Nashville with professional, safe, luxury transportation that is unmatched and guaranteed to meet your satisfaction. Our rates are competitively priced and are cheaper than Uber, Lyft and other providers. Come ride with Regal today!" />
    </div>
    <div className="why_regal-heading">
      <h1 className="none">A Luxury Driving Experience Reimagined</h1>
      <p>See why Regal is #1</p>
    </div>
    <div className="why_regal-container">
      <Feature title="We're Fast" text="No matter where you need to be we'll be there for you. " />
      <Feature title="Unbeatable Service" text="Regardless of your destination we're here to serve you with professional service around the clock" />
      <Feature title="Fair Pricing" text="Competitors like Uber and Lyft have major spikes in cost and fees, don't deal with the hassle of fees ride Regal" />
    </div>
  </div>
);

export default Whyregal;
