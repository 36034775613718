import React from "react";

import arrowRight from '../../assets/arrow-right.png'; 
/* import button from '../Button'; */
import { useForm } from "react-hook-form";
import "./contactform.css"

import ErrorContainer from "./ErrorContainer";

const FormContainer = ({sidebar}) => {
  /* sendgrid mail auth */
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [sentForm, setSentForm] = React.useState(false);
  const [isSideBar, setIsSideBar] = React.useState(sidebar);
  const onSubmit = (data) => {

    fetch(`/.netlify/functions/addToList`, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(response => response.json())
        .then(res => {
      if(res.success) {
        setSentForm(true);
      } else {
        setSentForm(false);
      }
    }).catch(err => {
      console.log(err);
    })
  }
  /* */
  if(sentForm) {
    return (
        <div className="form-container d-flex text-center row p-5">
          <div className="col">
            <h2>site contact form</h2>
          </div>
        </div>
    )
  } else {
    return (
        <div className="form-content">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <input
                      type="text"
                      placeholder="First Name"
                      maxLength={100}
                      className="form-control"
                      {...register("firstName", { required: "First name is required." })}
                  />
                  <ErrorContainer errors={errors} name="firstName"/>

                </div>
                <div className="col">
                  <input
                      type="text"
                      placeholder="Last Name"
                      defaultValue=""
                      data-msg-required="Please enter your last name."
                      maxLength={100}
                      className="form-control "
                      name="name"
                      {...register("lastName", { required: "Last name is required" })}
                  />
                  <ErrorContainer errors={errors} name="lastName"/>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                      type="email"
                      defaultValue=""
                      placeholder="Email Address"
                      maxLength={100}
                      className="form-control"

                      {...register("email", {
                        required: "Please enter a valid email address.",
                        pattern: /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/
                      })}
                  />
                  <ErrorContainer errors={errors} name="email"/>
                </div>
                <div className="col">
                  <input
                      type="text"
                      placeholder="Phone Number"
                      maxLength={100}
                      className="form-control "
                      name="name"
                      {...register("phone", { required: "Please enter your phone number." })}
                  />
                  <ErrorContainer errors={errors} name="phone"/>
                </div>
              </div>
              <div className="row">
                <div className="col">
              <textarea
                  maxLength={5000}
                  placeholder="Comments or Message"
                  rows={8}
                  className="form-control mb-2"
                  name="message"

                  {...register("message", { required: "Please enter your message." })}
              />
                  <ErrorContainer errors={errors} name="message"/>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: 'black',}}>

                <button
                    title="Submit Now"
                    type="submit"
                >
                  Request Ride/ Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
    );
  }


};

export default FormContainer;