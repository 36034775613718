import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Reserving Your Ride Is Fast',
    text: 'Booking is quick, Call: (615) 314-5400 or fill out our form above.' ,
    
  },
  {
    title: 'The Perfect Ride Anywhere',
    text: 'All vehicles in our fleet are luxury SUVs guranteed to impress.',
  },
  {
    title: 'Safety Is Priority',
    text: 'All our drivers are background checked and are fully certified.',
  },
  {
    title: 'Need A Chauffeur All Day?',
    text: 'We offer private chauffeur packages to help you get anywhere, quickly and most importantly in style.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding section__margin" id="features">
    <div className="gpt3__features-heading">
      <h1 className="none">There are so many reasons to choose Regal. Come join us and see why!</h1>
      <p>Book a ride today!</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
